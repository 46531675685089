<template>
	<div class="___auth">
		<div
			class="
				decorate
				flex
				items-center
				justify-center
				flex-col
				md-down:hidden
				relative
			"
		>
			<div class="mb-5">
				<img
					src="@/assets/images/logo/luca.svg"
					alt="Luca Education"
					title="Luca Education"
					style="width: 220px; height: 200px"
				/>
			</div>
			<p class="font-semibold text-neutral-500 text-center w-64 italic">
				"Cha mẹ đồng hành, thầy cô kham nhẫn, trẻ thơ hạnh phúc"
			</p>
			<div
				class="
					text-sm
					font-medium
					text-neutral-700
					pl-1
					absolute
					left-4
					bottom-4
				"
			>
				{{ version }}
			</div>
		</div>
		<div class="flex items-center justify-center flex-col" v-if="false">
			<img src="@/assets/images/events/logon-noel.svg" alt="" />
			<p class="text-neutral-600 mt-2">
				Chúc thầy cô một Giáng Sinh an lành!
			</p>
		</div>
		<div class="___auth__content flex items-center justify-center w-full">
			<slot></slot>
		</div>
	</div>
</template>
<script>
import store from '@/store';

export default {
	computed: {
		version() {
			return process?.env?.VUE_APP_VERSION;
		},
	},

	mounted() {
		store.dispatch('setAlertMessageDontHide', {
			message: '',
			type: 'error',
		});
	},
};
</script>
<style lang="scss">
@import './src/assets/styles/responsive';

.___auth {
	height: 100vh;
	width: 100vw;
	position: fixed;
	display: flex;
	//background: url('../../assets/images/events/background-noel.svg') no-repeat center center;
	//background-size: cover;

	.decorate {
		height: 100%;
		width: 320px;
		background: url('../../assets/images/background/auth.png') no-repeat
			center center;
		background-size: cover;

		img {
			object-fit: cover;
		}
	}

	&__content {
		min-width: 350px;
		overflow-y: auto;
	}

	._page-view {
		max-width: 530px;
		width: 100%;
		padding: 40px;

		&__title {
			font-size: 33px;
			line-height: 40px;
			letter-spacing: -0.02em;
		}

		.forgot-password {
			font-size: 14px;
			line-height: 17px;
			text-decoration-line: underline;
			cursor: pointer;
		}

		.error {
			font-size: 12px;
			line-height: 18px;
			margin-top: 10px;
		}

		.error-icon {
			height: 16px;
			width: 16px;
		}
	}

	@include media-breakpoint-up(xl) {
		.decorate {
			width: 460px;
		}
	}
}
</style>
